import {
  createRouter,
  // createWebHistory
  createWebHashHistory
} from 'vue-router'
// import layout from '@/layout'
import UserManageRouter from './modules/UserManage'
import RoleListRouter from './modules/RoleList'
import PermissionListRouter from './modules/PermissionList'
import ArticleRouter from './modules/Article'
import ArticleCreaterRouter from './modules/ArticleCreate'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [
  UserManageRouter,
  RoleListRouter,
  PermissionListRouter,
  ArticleRouter,
  ArticleCreaterRouter
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/wechatLogin',
    name: 'wechatLogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/wechat/wechatLogin')
  },
  {
    path: '/wxuser',
    name: 'wxuser',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/wechat/wxUserinfo')
  },
  {
    path: '/index',
    name: 'index',
    component: () =>
      import('@/views/home.vue')
  },
  {
    // path: '/task/:id',
    path: '/task',
    name: 'task',
    component: () =>
      import('@/views/task/show.vue')
  },
  {
    path: '/addTask',
    name: 'addTask',
    component: () =>
      import('@/views/task/addTask.vue')
  },
  {
    path: '/personalInformation',
    name: 'personalInformation',
    component: () =>
      import('@/views/user/personalInformation.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    redirect: '/index',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/index'),
        meta: {
          title: 'profile',
          icon: 'el-icon-user'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  },
  {
    path: '/user',
    name: '/user',
    component: () => import('../views/user'),
    redirect: '/usindex',
    children: [
      {
        path: '/usindex',
        name: '/usindex',
        component: () => import('@/views/user/welcome.vue')
      },
      {
        path: '/chongzhi',
        name: '/chongzhi',
        component: () => import('@/views/user/chongzhi.vue')
      },
      {
        path: '/tixian',
        name: '/tixian',
        component: () => import('@/views/user/tixian.vue')
      },
      {
        path: '/ustask',
        name: '/ustask',
        component: () => import('@/views/user/usTask.vue')
      },
      {
        path: '/mytaskshow',
        name: '/mytaskshow',
        component: () => import('@/views/user/mytaskshow.vue')
      },
      {
        path: '/gztaskshow',
        name: '/gztaskshow',
        component: () => import('@/views/user/gztaskshow.vue')
      },
      {
        path: '/certification',
        name: '/certification',
        component: () => import('@/views/user/certification.vue')
      },
      {
        path: '/enterprise',
        name: '/enterprise',
        component: () => import('@/views/user/enterprise.vue')
      },
      {
        path: '/introduce',
        name: '/introduce',
        component: () => import('@/views/user/introduce.vue')
      },
      {
        path: '/message',
        name: '/message',
        component: () => import('@/views/user/message.vue')
      },
      {
        path: '/evaluation',
        name: '/evaluation',
        component: () => import('@/views/user/taskEvaluation.vue')
      },
      {
        path: '/level',
        name: '/level',
        component: () => import('@/views/user/level.vue')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    // console.log('初始化路由表=', menus)
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

// console.log('router---', process.env.NODE_ENV)
// const router = createRouter({
//   history:
//     process.env.NODE_ENV === 'production'
//       ? createWebHistory()
//       : createWebHashHistory(),
//   routes: publicRoutes
// })

const router = createRouter({
  // history: createWebHistory(), // 去掉地址栏 #
  history: createWebHashHistory(), // 显示地址栏 #
  routes: publicRoutes
})
// console.log('router2222---', router.options)

export default router
