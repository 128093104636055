<template>
  <router-view />
</template>

<style lang="scss">
@import './assets/css/common.scss';
@import './assets/css/public.scss';
@import './assets/css/cc.scss';
#app {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
