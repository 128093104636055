/**
 * 常用工具类
 */
var tools = {
  /**
   * 基础常用
   */
  basic: {
    /**
     * 获取url参数字符串(没有返回null)
     * @param {*} name
     */
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    /**
     * 去除参数空数据(用于向后台传递参数的时候)
     * @param {Object} obj [参数对象]
     */
    filterEmptyData(obj) {
      for (var prop in obj) {
        if (obj[prop] === '') {
          delete obj[prop]
        }
      }
      return obj
    }
  },

  /**
   * 字符串操作
   */
  string: {
    /**
     * 字符串判空
     * @param value
     * @returns {boolean}
     */
    isEmpty(value) {
      return value.trim().replace(/(^s*)|(s*$)/g, '').length === 0
    },

    /**
     * 去除字符串空格
     * @param str 要处理的字符串
     * @param type 1：所有空格 2：前后空格 3：前空格 4：后空格
     */
    strTrim(str, type) {
      switch (type) {
        case 1:
          return str.replace(/\s+/g, '')
        case 2:
          return str.replace(/(^\s*)|(\s*$)/g, '')
        case 3:
          return str.replace(/(^\s*)/g, '')
        case 4:
          return str.replace(/(\s*$)/g, '')
        default:
          return str
      }
    }
  },

  /**
   * 数组相关方法
   */
  array: {
    /**
     * 是否空数组
     * @param {*} val
     */
    isArrayEmpty(val) {
      if (val && val instanceof Array && val.length > 0) {
        return false
      } else {
        return true
      }
    },
    /**
     * 数组去重
     * 用的是Array的from方法
     * @param arr 数组
     * @returns 去重后的数组
     */
    removeRepeatArray(arr) {
      return Array.from(new Set(arr))
    },

    /**
     * 获取数组的最大值，最小值，只针对数字类型的数组
     * @param arr 数组
     * @param type 0：最小值，1：最大值
     * @returns
     */
    compareArr(arr, type) {
      if (type === 1) {
        return Math.max.apply(null, arr)
      } else {
        return Math.min.apply(null, arr)
      }
    },

    /**
     * 返回某个元素在数组中出现的次数
     * @param arr 数组
     * @param ele 要查找的元素
     * @returns {Number} 出现的次数
     */
    getEleCount(arr, ele) {
      let num = 0
      for (let i = 0, len = arr.length; i < len; i++) {
        if (ele === arr[i]) {
          num++
        }
      }
      return num
    },

    /**
     * 简单数组排序，针对数字数组
     * @param type 1：降序，0：升序
     */
    sortArr(arr, type) {
      if (type === 1) {
        // 降序
        arr.sort(function(a, b) {
          return b - a
        })
      } else {
        arr.sort(function(a, b) {
          return a - b
        })
      }
      return arr
    }
  },

  /**
   * 日期时间格式化
   */
  datetime: {
    /**
     * 格式化日期方法
     * 例：this.$tools.dateFormat('Dec 27, 2017 3:18:14 PM') 得到 '2020-06-10 10:10:10'
     */
    dateFormat(date, v) {
      if (!date) return ''
      var dateFormat = new Date(date)
      var year = dateFormat.getFullYear()
      var month = dateFormat.getMonth() + 1
      if (month < 10) month = '0' + month
      var mydate = dateFormat.getDate()
      if (mydate < 10) mydate = '0' + mydate
      var hours = dateFormat.getHours()
      if (hours < 10) hours = '0' + hours
      var minutes = dateFormat.getMinutes()
      if (minutes < 10) minutes = '0' + minutes
      var seconds = dateFormat.getSeconds()
      if (seconds < 10) seconds = '0' + seconds
      var time = `${year}-${month}-${mydate} ${hours}:${minutes}:${seconds}`
      if (v) {
        time = `${year}-${month}-${mydate}`
      }
      return time
    },

    /**
     * 时间戳转换
     * 例：this.$tools.timestampToTime(1591755010000) 得到 '2020-06-10 10:10:10'
     */
    timestampToTime(timestamp) {
      var date = new Date(timestamp)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s
    },
    /**
     * 将字符串时间转换为时间戳
     * @param {string} date
     */
    getDateTime(date) {
      let timestamp = ''
      if (date) {
        date = date.replace(/-/g, '/')
        timestamp = new Date(date).getTime()
      }
      return timestamp
    }
  },

  /**
   * 本地存储
   */
  storage: {
    getItem (key) {
      const data = window.localStorage.getItem(key)
      try {
        return JSON.parse(data)
      } catch (err) {
        return data
      }
    },
    /**
     * 设置本地localStorage
     * @name {String} 数据对象的KEY
     * @data {all} 对应key的数据
     */
    setStorage(name, data) {
      var storage = window.localStorage
      storage.setItem(name, JSON.stringify(data))
    },

    /**
     * 拿到本地localStorage
     * @name {String} 数据对象的KEY
     */
    getStorage(name) {
      var storage = window.localStorage
      var data = JSON.parse(storage.getItem(name))
      return data
    },

    /**
     * 设置本地sessionStorage
     * @name {String} 数据对象的KEY
     * @data {all} 对应key的数据
     */
    setSessionStorage(name, data) {
      var storage = window.sessionStorage
      storage.setItem(name, JSON.stringify(data))
    },

    /**
     * 拿到本地sessionStorage
     * @name {String} 数据对象的KEY
     */
    getSessionStorage(name) {
      var storage = window.sessionStorage
      var data = JSON.parse(storage.getItem(name))
      return data
    }
  },

  /**
   * Cookie
   */
  cookie: {
    /**
     * 设置cookie
     * @param name cookie名称
     * @param value cookie值
     * @param iDay cookie的时间
     */
    setCookie(name, value, iDay) {
      var oDate = new Date()
      oDate.setDate(oDate.getDate() + iDay)
      document.cookie =
        name +
        '=' +
        escape(value) +
        (iDay == null ? '' : ';expires=' + oDate.toGMTString())
    },
    /**
     * 获取cookie的值
     * cookieVal 参数是cookie中的key返回value
     * @type {string}
     */
    getCookie(cookieVal) {
      var arr = null
      var reg = new RegExp('(^| )' + cookieVal + '=([^;]*)(;|$)')
      arr = document.cookie.match(reg)
      if (arr) return arr[2]
      else return null
    },
    /**
     * 删除cookie
     * @param name cookie名称
     */
    removeCookie(name) {
      var oDate = new Date()
      oDate.setTime(oDate.getTime() - 1)
      var value = tools.cookie.getCookie(name)
      if (value !== null) document.cookie = name + '=' + value + ';expires=' + oDate.toGMTString()
    }
  },

  /**
   * 验证
   */
  verify: {
    /**
     * 邮箱
     * @param {*} s
     */
    isEmail(s) {
      return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
        s
      )
    },

    /**
     * 手机号码
     * @param {*} s
     */
    isMobile(s) {
      return /^1[3456789]\d{9}$/.test(s)
    },

    /**
     * 电话号码
     * @param {*} s
     */
    isPhone(s) {
      return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
    },

    /**
     * URL地址
     * @param {*} s
     */
    isURL(s) {
      // return /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(s)
      return /^http[s]?:\/\/.*/.test(s)
    },

    /**
     * IPv4地址
     * @param {*} s
     */
    isIP(s) {
      return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        s
      )
    },

    /**
     * IPv6地址
     * @param {*} s
     */
    isIPv6(s) {
      return /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/.test(
        s
      )
    },

    /**
     * 身份证
     * @param {*} s
     */
    isId(s) {
      if (checkCode(s)) {
        var date = s.substring(6, 14)
        if (checkDate(date)) {
          if (checkProv(s.substring(0, 2))) {
            return true
          }
        }
      }
      return false
    },

    /**
     * 是否是图片类型
     * @param {*} type
     */
    isImg(type) {
      if (
        type !== 'image/jpg' &&
        type !== 'image/jpeg' &&
        type !== 'image/png' &&
        type !== 'image/gif'
      ) {
        this.$message.error('只支持jpg、png、gif格式的图片！')
        return false
      }
      return true
    },

    /**
     * 是否是视频类型['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb']
     * @param {*} type
     */
    isVideo(type) {
      if (
        type !== 'video/mp4' &&
        type !== 'video/ogg' &&
        type !== 'video/flv' &&
        type !== 'video/avi' &&
        type !== 'video/wmv' &&
        type !== 'video/rmvb'
      ) {
        this.$message.error('只支持mp4、ogg、flv、avi、wmv、rmvb格式的视频！')
        return false
      }
      return true
    },

    /**
     * 是否是压缩包类型
     * @param {*} type
     */
    isZip(type) {
      if (type !== 'application/zip') {
        this.$message.error('只支持zip格式的图片！')
        return false
      }
      return true
    },

    /**
     * 是否是文档类型['application/msword'、'application/pdf']
     * @param {*} type
     */
    isDoc(type) {
      if (type !== 'application/zip' && type !== 'application/pdf') {
        this.$message.error('只支持doc、docx、pdf格式的文档！')
        return false
      }
      return true
    }
  },

  /**
   * 对象深浅拷贝
   */
  clone: {
    /**
     * 对象深拷贝
     * @param  obj 对象
     */
    cloneObj(obj) {
      if (!obj && typeof obj !== 'object') {
        throw new Error('error arguments', 'shallowClone')
      }
      const newObj = obj.constructor === Array ? [] : {}
      Object.keys(obj).forEach(keys => {
        if (obj[keys] && typeof obj[keys] === 'object') {
          newObj[keys] = deepClone(obj[keys])
        } else {
          newObj[keys] = obj[keys]
        }
      })
      return newObj
    },

    /**
     * @function deepCopy 浅深拷贝
     * @param  obj 对象
     */
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    }
  },

  /**
   * 数据转换
   */
  data: {
    /**
     * 树形数据转换
     * @param {*} data
     * @param {*} id
     * @param {*} pid
     */
    treeDataTranslate(data) {
      // 删除所有children,以防止多次调用
      data.forEach(function(item) {
        delete item.children
      })

      // 将数据存储为以id为KEY的map索引数据列
      var map = {}
      data.forEach(function(item) {
        map[item.id] = item
      })
      var val = []
      data.forEach(function(item) {
        // 以当前遍历项，的pid,去map对象中找到索引的id
        var parent = map[item.pid]
        // 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
        if (parent) {
          ;(parent.children || (parent.children = [])).push(item)
        } else {
          // 如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
          val.push(item)
        }
      })
      return val
    }
  },

  /**
   * zd颜色转换
   */
  color: {
    // zd颜色匹配 **/
    zdToColor(val, isobj) {
      // console.log('zd=c===',val,isobj);
      // var corAr = ['#18ff10', '#ffb310', '#ff2ed2', '#62b7fd', '#3af78f', '#9b65ff', '#ffff55'] // 默认色值
      var icor = '#9b65ff'
      if (isobj !== undefined) {
        isobj.forEach(icr => {
          if (icr.base_name === val) {
            icor = icr.colour
          }
        })
      }
      return icor
    }
  },

  /**
   * 分秒转换
   */
  getTime: {
    formatSeconds(theTime) {
      let theTime1 = 0
      let theTime2 = 0
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }
      let result = '' + parseInt(theTime) + '秒'
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + result
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + result
      }
      return result
    }
  }
}

/**
 * 身份验证号验证
 */
/**
 * 校验码校验
 * @param {*} val
 */
var checkCode = function(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
  var code = val.substring(17)
  if (p.test(val)) {
    var sum = 0
    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i]
    }
    if (parity[sum % 11] === code.toUpperCase()) {
      return true
    }
  }
  return false
}
/**
 * 出生日期码校验
 * @param {*} val
 */
var checkDate = function(val) {
  var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/
  if (pattern.test(val)) {
    var year = val.substring(0, 4)
    var month = val.substring(4, 6)
    var date = val.substring(6, 8)
    var date2 = new Date(year + '-' + month + '-' + date)
    if (date2 && date2.getMonth() === parseInt(month) - 1) {
      return true
    }
  }
  return false
}
/**
 * 省级地址码校验
 * @param {*} val
 */
var checkProv = function(val) {
  var pattern = /^[1-9][0-9]/
  var provs = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门'
  }
  if (pattern.test(val)) {
    if (provs[val]) {
      return true
    }
  }
  return false
}

export default tools
