import md5 from 'md5'
/**
 * 存储数据
 */
export const setItem = (key, value) => {
  // 将数组、对象类型的数据转化为 JSON 字符串进行存储
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(key, value)
}

/**
 * 获取数据
 */
export const getItem = key => {
  const data = window.localStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

/**
 * 删除数据
 */
export const removeItem = key => {
  window.localStorage.removeItem(key)
}

/**
 * 删除所有数据
 */
export const removeAllItem = key => {
  window.localStorage.clear()
}

/**
 * 返回ID
 */
export const toUsId = tk => {
  var mad = tk.split('+')
  return mad[1]
}

/**
 * 对请求的数据做加密处理
 */
export const sign = data => {
  // console.log('对请求的数据做加密处理---', data)
  data.timestamp = Date.parse(new Date()) / 1000
  data.key = 'niCaiDaoYeBieShuo'
  var jv = []
  for (var i in data) {
    if (i !== 'etxt' && i !== 'etxt2' && i !== 'sign') {
      jv.push(i + '=' + encodeURIComponent(data[i]))
    }
  }
  var jmq = jv.sort().join('&') // 排序并转成字符串
  // console.log('加密前=', jmq)
  // var md5 = function(str){
  //   var crypto_md5 = crypto.createHash('md5');
  //   crypto_md5.update(str, 'utf8'); // 加入编码
  //   return crypto_md5.digest('hex');
  // }
  data.sign = md5(jmq, 'utf8')
  delete data.key
  // console.log('加密后=', data)
  return data
}
