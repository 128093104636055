const whiteList = ['/index', '/import', '/404', '/401'] // 白名单

/**
 * path 是否需要被缓存
 * @param {*} path
 * @returns
 */
export function isTags(path) {
  return !whiteList.includes(path)
}
