import { login } from '@/api/login'
// import md5 from 'md5'
// import { setItem, getItem, removeAllItem } from '@/utils/storage'
// import { TOKEN } from '@/constant'

import tools from '@/utils/tools.js'
import store from '@/store'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    token: tools.storage.getItem('token') || ''
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      tools.storage.setItem('token', token)
      debugger
      // window.localStorage.setItem('token', data.data.token)
    }
  },
  actions: {
    // 登录请求
    login(context, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username,
          password
          // password: md5(password)
        })
          .then(data => {
            console.log('user.js-', data)
            window.localStorage.setItem('token', data.data.token)
            window.localStorage.setItem('userinfo', JSON.stringify(data.data))
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async getUserInfo(context) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', res)
      return res
    },
    logout() {
      store.state.user.token = null
      // 退出 清理路由
      // resetRouter()
      // this.commit('user/setToken', '')
      // this.commit('user/setUserInfo', {})
      // removeAllItem()

      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userinfo')
      router.push('/login')
    }
  }
}
