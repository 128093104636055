// 快捷访问
// import { MAIN_COLOR } from '@/constant'
// import { getItem } from '@/utils/storage'
// import { generateColors } from '@/utils/theme'
// console.log(store.getters)
const getters = {
  token: state => state.user.token,
  userinfo: state => {
    return state.user.userinfo
  },
  // /**
  //  * @returns true 表示已存在用户信息
  //  */
  hasUserInfo: state => {
    return JSON.stringify(state.user.userinfo) !== '{}'
  }
  // cssVar: state => {
  //   return {
  //     ...state.theme.variables,
  //     ...generateColors(getItem(MAIN_COLOR))
  //   }
  // },
  // sidebarOpened: state => state.app.sidebarOpened,
  // language: state => state.app.language,
  // mainColor: state => state.theme.mainColor,
  // tagsViewList: state => state.app.tagsViewList
}
export default getters
